<template>
  <base-section
    id="404"
    space="16"
  >
    <v-container>
      <v-row>
        <v-col
          cols="12"
          md="12"
        >
          <v-card
            max-width="1200"
            flat
          >
            <v-col
              cols="12"
              md="12"
            >
              <base-info-card
                :title="$t('error404.mainline')"
              />
            </v-col>
          </v-card>
          <v-card
            class="rounded-xl"
            max-width="1200"
            flat
            color="#f4f4f3"
          >
            <v-container
              fluid
            >
              <v-row
                no-gutters
                align="center"
                align-content="center"
                justify="center"
              >
                <v-col
                  cols="12"
                  sm="12"
                >
                  <base-img
                    :src="require('@/assets/KIDIWI_services-candy.svg')"
                    class="mx-auto"
                    contain
                    max-width="500"
                  />

                  <div class="text-center">
                    <kdw-btn
                      id="btnGetMeOutOfHere"
                      :to="{ name: 'Home' }"
                    >
                      {{ $t('error404.getMeOutOfHere') }}
                    </kdw-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </base-section>
</template>

<script>
  export default {
    name: 'FourOhFour',

    provide: {
      heading: { align: 'center' },
    },
  }
</script>
